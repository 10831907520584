@charset "UTF-8";
input {
  outline: 0; }

.body_form {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 15px; }
  .body_form .title {
    width: 100%;
    margin: 80px auto 50px;
    text-align: center; }
    .body_form .title h1 {
      font-size: 50px; }
    .body_form .title span {
      font-size: 30px; }
  .body_form #mailform table, .body_form form table {
    width: 100%; }
    .body_form #mailform table tr, .body_form form table tr {
      border-bottom: 1px solid #CCC; }
      .body_form #mailform table tr th, .body_form form table tr th {
        width: 200px;
        color: #aaa;
        font-weight: lighter;
        text-align: left; }
        .body_form #mailform table tr th label, .body_form form table tr th label {
          display: inline-block;
          padding: 17px 15px 17px 0;
          line-height: 1.2em;
          font-weight: 700; }
      .body_form #mailform table tr td, .body_form form table tr td {
        line-height: 1.2em; }
        .body_form #mailform table tr td input[type=text], .body_form form table tr td input[type=text] {
          width: 100%;
          margin: 10px 0;
          padding: 8px 0;
          text-indent: 8px;
          background-color: #eee;
          border: none; }
        .body_form #mailform table tr td textarea, .body_form form table tr td textarea {
          width: 100%;
          margin: 15px 0;
          color: #aaa;
          padding: 10px;
          /* text-indent: 10px; */
          border: none; }
        .body_form #mailform table tr td input[type=radio] ~ span, .body_form form table tr td input[type=radio] ~ span {
          margin: 0px 30px 0px 5px;
          font-size: 0.75em;
          color: #041c2c;
          font-weight: 700;
          position: relative;
          top: 2px; }
      .body_form #mailform table tr .required, .body_form form table tr .required {
        float: right;
        position: relative;
        top: 18px;
        right: 20px;
        font-size: 0.5em;
        color: #051a2b;
        background-color: #ede939;
        padding: 3px 4px 2px;
        margin-right: 10px;
        font-weight: normal;
        border-radius: 2px;
        /*CSS3草案 */
        -webkit-border-radius: 2px;
        /*Safari,Google Chrome用 */
        -moz-border-radius: 2px;
        /*Firefox用 */ }
      .body_form #mailform table tr .check_validate, .body_form form table tr .check_validate {
        position: relative;
        margin: 10px 0; }
        .body_form #mailform table tr .check_validate input[type=text], .body_form form table tr .check_validate input[type=text] {
          margin: 0; }
  .body_form #mailform span.error, .body_form form span.error {
    position: absolute;
    white-space: nowrap;
    font-size: 14px;
    background: #52c6e2;
    padding: 8px;
    padding-right: 15px;
    border-radius: 4px;
    left: -185px;
    top: -10px;
    transform: translateY(-50%);
    color: #fff; }
    .body_form #mailform span.error:before, .body_form form span.error:before {
      content: url(../images/arrow_error.png);
      position: absolute;
      right: -17px;
      top: 45%; }
    .body_form #mailform span.error .closebtn, .body_form form span.error .closebtn {
      cursor: pointer; }
  .body_form #mailform #upload_area, .body_form form #upload_area {
    margin-bottom: 50px;
    padding: 15px 0px; }
    .body_form #mailform #upload_area .extension_note, .body_form form #upload_area .extension_note {
      display: inline-block; }
    .body_form #mailform #upload_area .upload_btn, .body_form form #upload_area .upload_btn {
      display: inline-block;
      position: relative;
      width: 250px;
      height: 40px;
      line-height: 2.3em;
      float: right;
      padding: 0;
      margin: 0 0 0 50px;
      color: #999;
      background-color: #eee;
      transition: all 0.3s;
      text-align: center;
      cursor: pointer; }
      .body_form #mailform #upload_area .upload_btn .browserfile, .body_form form #upload_area .upload_btn .browserfile {
        font-size: 0.7em;
        line-height: 2.3em; }
      .body_form #mailform #upload_area .upload_btn .upload_hidden, .body_form form #upload_area .upload_btn .upload_hidden {
        position: absolute;
        top: 0;
        left: 0; }
        .body_form #mailform #upload_area .upload_btn .upload_hidden input, .body_form form #upload_area .upload_btn .upload_hidden input {
          width: 100%;
          height: 40px;
          opacity: 0;
          cursor: pointer; }
        .body_form #mailform #upload_area .upload_btn .upload_hidden span.error, .body_form form #upload_area .upload_btn .upload_hidden span.error {
          left: -305px;
          top: -12px;
          padding: 0 8px; }
          .body_form #mailform #upload_area .upload_btn .upload_hidden span.error:before, .body_form form #upload_area .upload_btn .upload_hidden span.error:before {
            top: 25%; }
    .body_form #mailform #upload_area #filename, .body_form form #upload_area #filename {
      clear: both;
      text-align: center;
      padding: 5px;
      margin-top: 10px; }
  .body_form #mailform #submit_btn, .body_form form #submit_btn {
    width: 100%;
    float: left;
    margin: 15px auto 100px;
    position: relative;
    -webkit-transition: all .3s;
    transition: all .3s; }
    .body_form #mailform #submit_btn input, .body_form #mailform #submit_btn a, .body_form form #submit_btn input, .body_form form #submit_btn a {
      display: inline-block;
      width: 100%;
      height: 70px;
      line-height: 3.5em;
      background-color: #ede939;
      color: #0B847B;
      border: none;
      border-radius: 5px;
      /* CSS3草案 */
      -webkit-border-radius: 5px;
      /*Safari,Google Chrome用 */
      -moz-border-radius: 5px;
      /*Firefox用 */
      outline: none;
      text-align: center;
      text-decoration: none;
      font-weight: bold;
      font-size: 1.2em;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all .3s;
      transition: all .3s; }
      .body_form #mailform #submit_btn input:hover, .body_form #mailform #submit_btn a:hover, .body_form form #submit_btn input:hover, .body_form form #submit_btn a:hover {
        background-color: #004CA5;
        cursor: pointer;
        color: #ede939; }
    .body_form #mailform #submit_btn i, .body_form form #submit_btn i {
      font-size: 1.5em;
      position: absolute;
      right: 35%;
      top: 17px; }
    .body_form #mailform #submit_btn:hover i, .body_form form #submit_btn:hover i {
      color: #fff; }
  .body_form #mailform #submit_btn_confirm, .body_form form #submit_btn_confirm {
    overflow: hidden;
    margin: 50px auto; }
    .body_form #mailform #submit_btn_confirm .go_to_step_1, .body_form form #submit_btn_confirm .go_to_step_1 {
      border-radius: 5px;
      /* CSS3草案 */
      -webkit-border-radius: 5px;
      /*Safari,Google Chrome用 */
      -moz-border-radius: 5px;
      /*Firefox用 */
      display: block;
      padding: 15px 0;
      margin: 30px 2% 0 0;
      width: 49%;
      line-height: 42px;
      float: left;
      position: relative;
      z-index: 2;
      overflow: hidden;
      background: #999999;
      border: 2px solid #EDE939;
      opacity: 1;
      color: #fff;
      text-align: center;
      border-style: none;
      box-sizing: border-box;
      transition: all .3s;
      cursor: pointer; }
      .body_form #mailform #submit_btn_confirm .go_to_step_1 i, .body_form form #submit_btn_confirm .go_to_step_1 i {
        font-size: 1.5em;
        position: relative;
        top: 3px;
        padding-right: 10px; }
      .body_form #mailform #submit_btn_confirm .go_to_step_1:hover, .body_form form #submit_btn_confirm .go_to_step_1:hover {
        background-color: #eee; }
    .body_form #mailform #submit_btn_confirm .btn_confirm, .body_form form #submit_btn_confirm .btn_confirm {
      border-radius: 5px;
      /* CSS3草案 */
      -webkit-border-radius: 5px;
      /*Safari,Google Chrome用 */
      -moz-border-radius: 5px;
      /*Firefox用 */
      display: inline-block;
      width: 49%;
      margin-top: 30px;
      line-height: 4.5em;
      position: relative;
      z-index: 2;
      overflow: hidden;
      background-color: #EDE939;
      border: none;
      font-weight: bold;
      outline: none;
      text-align: center;
      text-decoration: none;
      transition: all .3s;
      box-sizing: border-box; }
      .body_form #mailform #submit_btn_confirm .btn_confirm input, .body_form form #submit_btn_confirm .btn_confirm input {
        width: 100%;
        height: 70px;
        margin: 0;
        background: none;
        border: none;
        color: #004CA5;
        font-weight: bold;
        text-align: center;
        transition-duration: 0.3s; }
      .body_form #mailform #submit_btn_confirm .btn_confirm i, .body_form form #submit_btn_confirm .btn_confirm i {
        font-size: 1.5em;
        position: absolute;
        top: 25px;
        right: 100px; }
      .body_form #mailform #submit_btn_confirm .btn_confirm:hover, .body_form form #submit_btn_confirm .btn_confirm:hover {
        background-color: #004CA5; }
        .body_form #mailform #submit_btn_confirm .btn_confirm:hover input, .body_form form #submit_btn_confirm .btn_confirm:hover input {
          color: #EDE939; }
        .body_form #mailform #submit_btn_confirm .btn_confirm:hover i, .body_form form #submit_btn_confirm .btn_confirm:hover i {
          color: #ede939; }
  .body_form .confirmform p:first-of-type {
    font-size: 1.5em;
    line-height: 1.5em;
    text-align: center;
    padding: 10px 0 40px; }
  .body_form .confirmform table tr td {
    padding: 17px 0; }

.thankspage {
  text-align: center; }
  .thankspage .title h1 {
    font-size: 50px; }
  .thankspage .title span {
    font-size: 35px;
    margin-bottom: 30px; }
  .thankspage .sub_title {
    font-size: 35px;
    margin-bottom: 30px; }
  .thankspage .thanks_mesage {
    color: #041c2c;
    margin-bottom: 60px;
    line-height: 2em; }
  .thankspage .go_top_page a {
    border-radius: 5px;
    /* CSS3草案 */
    -webkit-border-radius: 5px;
    /*Safari,Google Chrome用 */
    -moz-border-radius: 5px;
    /*Firefox用 */
    padding: 0px 100px;
    display: inline-block;
    height: 45px;
    background: #004CA5;
    text-decoration: none;
    color: white;
    line-height: 45px;
    font-size: 14px;
    text-align: center;
    transition: 0.3s; }
    .thankspage .go_top_page a:hover {
      background-color: #EDE939; }

@media only screen and (max-width: 768px) {
  .body_form #mailform table tr th {
    display: block;
    width: 100%; }
  .body_form #mailform table tr td {
    display: block;
    width: 100%; }
    .body_form #mailform table tr td input[type=radio] {
      margin: 10px 0px; }
  .body_form #mailform table tr .required {
    position: static;
    float: initial;
    top: 15px; }
  .body_form #mailform span.error {
    left: 130px;
    top: -30px; }
    .body_form #mailform span.error:before {
      content: url(../images/arrow_error_sp.png);
      right: 40px;
      top: 85%; }
  .body_form #mailform #upload_area .extension_note {
    width: 58.33333333%; }
  .body_form #mailform #upload_area .upload_btn {
    width: 41.66666667%; }
    .body_form #mailform #upload_area .upload_btn .upload_hidden span.error {
      left: -10px;
      top: -25px; }
      .body_form #mailform #upload_area .upload_btn .upload_hidden span.error:before {
        top: 65% !important; }
  .body_form #mailform #submit_btn_confirm .btn_confirm i {
    position: absolute;
    right: 30px; } }

/*# sourceMappingURL=style.css.map */